import React, { useState } from "react";
import _ from "lodash";

import { graphql } from "gatsby";

import "../css/app.css";

import Webflow from "../modules/webflow";
import Material from "../modules/material";
import ImgList from "../modules/imagelist";
import Renoir from "../modules/renoir";
import Custom1 from "../modules/custom1";
import Homepage from "../modules/homepage";
import Homepage2 from "../modules/homepage-2";

import KimaHpOverlay from "../modules/kima-hp-overlay";
import KimaHPList from "../modules/kima-hp-list";
import KimaList from "../modules/kima-list";

import WmaHpOverlay from "../modules/wma-hp-overlay";
import WmaHPList from "../modules/wma-hp-list";
import WmaList from "../modules/wma-list";

import WrmHp from "../modules/wrm-hp";
import WrmHpEn from "../modules/wrm-hp-en";
import WrmList from "../modules/wrm-list";
import WrmList2 from "../modules/wrm-list2";

import M5kHp from "../modules/m5k-hp";
import M5kList from "../modules/m5k-list";
import M5kList2 from "../modules/m5k-list2";

const RouterPage = ({ data, ...props }) => {
    const { strapiEntities: entity, allFile: files, allStrapiI18NLocale: { nodes: locales } } = data;
    //allFile needed here to render gatsbyImages for strapi_components 

    const { module } = entity;

    const selectedModule = module?.name;

    const topbarConfig = _.find(entity.Components, { strapi_component: 'component.topbar' });

    const [fullscreenMode, setFullscreenMode] = useState(entity.Components?.some(component => component.fullscreen === true));

    const viewProps = {
        entity,
        selectedModule,
        files,
        locales,
        config: topbarConfig || {},
        showBackbutton: entity.identifier !== 'root',
        fullscreenMode,
        setFullscreenMode
    }

    const getViewModule = () => {
        switch (selectedModule) {
            case 'imagelist':
                return ImgList;
            case 'renoir':
                return Renoir;

            case 'material':
                return Material;

            case 'webflow':
                return Webflow;

            case 'custom1':
                return Custom1;
            case 'homepage':
                return Homepage;
            case 'homepage-2':
                return Homepage2;
            case 'kima-hp-overlay':
                return KimaHpOverlay;
            case 'kima-hp-list':
                return KimaHPList;
            case 'kima-list':
                return KimaList;
            case 'wma-hp-overlay':
                return WmaHpOverlay;
            case 'wma-hp-list':
                return WmaHPList;
            case 'wma-list':
                return WmaList;
            case 'wrm-hp':
                return WrmHp;
            case 'wrm-hp-en':
                return WrmHpEn;
            case 'wrm-list':
                return WrmList;
            case 'wrm-list2':
                return WrmList2;
            case 'm5k-hp':
                return M5kHp;
            case 'm5k-list':
                return M5kList;
            case 'm5k-list2':
                return M5kList2;
            default:
                return React.Fragment;
        }
    };
    const ViewModule = getViewModule();

    return <>
        <ViewModule  {...props} {...viewProps} />

    </>;
};

export default RouterPage;

export const query = graphql`
    query ($entityId: String!, $locale: String!) {
        strapiEntities(identifier: {eq: $entityId}, locale: {eq: $locale}) {
            identifier
            title
            locale
            localizations { locale }
            shortDesc
            smallImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 150)
                    }
                }
            }
            largeImage {
                localFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            strapiChildren {
                title
                identifier
                locale
                smallImage {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 500)
                        }
                    }
                }
                Roles
            }
            Components
            Roles
            module {
                name
            }
        }
        allFile {
            nodes {
                id
                name
                publicURL
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        allStrapiI18NLocale {
            nodes {
                name
                code
                isDefault
            }
        }
        
    }
`;




/*TODO - conditional module-loading confuses webpack/gatsby-offline
class RouterPage extends React.Component {
    state = {
        Module: null,
    };

    componentDidMount = async () => {
        if (this.props.data.strapiEntities.Module?.length) {
            const module = await import(`../modules/${this.props.data.strapiEntities.Module[0]?.strapi_component.split('.')[1]}`);
            this.setState({ Module: module.default });
        }
    };

    render = () => {
        const { Module } = this.state;
        let entity = this.props.data.strapiEntities;
        entity.config = JSON.parse(entity.configJSON);//TODO - remove if remains unused

        if (!entity.Module || !entity.Module[0]?.strapi_component) return <>No module selected.</>;
        else 
            return (Module === null ? <></> : <Module showBackbutton={entity.identifier!=='root'} entity={entity} config={entity.Module[0] || {}} />);

    }
}*/
